import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Section, TitleBigText, TitleComment, ContainerBigText, TextBigText, ButtonWhasApp, ImgInsta } from './styled';
import HorizontalLine from '../simpleHorizontalLine'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Insta from '../../images/insta.png'


const BigTextPages = (props) => {
    const [isMobile, setIsMobile] = useState('row')

    const screen = window.screen.width < 1024

    useEffect(() => {
        if (screen === true)
        setIsMobile('column')
    }, [])



    const { content } = props
    return (
        <div>
            <Section>
                <ContainerBigText>

                    <TitleBigText>{content.title}</TitleBigText>
                    <TitleComment>{content.comment}</TitleComment>

                </ContainerBigText>
            </Section>
            <ContainerBigText>
                <HorizontalLine text={content.title}></HorizontalLine>
                <TextBigText dangerouslySetInnerHTML={{ __html: content.text }}></TextBigText>
                <div style={{display: 'flex', paddingLeft: '10%'}}>
                <ButtonWhasApp>
                        <a href='https://whatsform.com/G9qhD2' target={'blank'}>
                            <WhatsAppIcon style={{ fontSize: '30', color: '#fff', padding: '5px' }} />
                        </a>
                    </ButtonWhasApp>

                <a href='https://instagram.com/santarosaescolar?igshid=YmMyMTA2M2Y='>
                <div style={{ display: 'flex', alignItems: 'center', gap:'5px'}} ><img src={Insta} alt='Instagram' style={{width: isMobile? '40px': '50px', paddingLeft: '10%'}}></img><span>Instagram</span> </div>
                </a>


</div>
                


            </ContainerBigText>
            {content.whatsApp ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: isMobile }}>
                    <img src={content.whatsApp.qr} alt='qrcode' style={{ width: '300px' }} />
                </div>

                : ''}
                
        </div>
    )
}
export default BigTextPages