import React from 'react';
import AddTransport from '../components/addTransport';


export const Page5 = () => {

  return (
    <div>
      <AddTransport />
    </div>
  );
};

export default Page5







